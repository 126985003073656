import {
  Breadcrumbs,
  Button,
  ContainerScreen,
  IconWithTooltip,
  Loader,
} from 'components'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOfficeHours } from 'shared/hooks'
import { CoverageCard } from './components/CoverageCard/CoverageCard'

import { ReactComponent as Info } from '../../assets/exclamation.svg'
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'

import { PartitionStatus } from 'services/patrimony/types'
import { groupIntoBusinessDays } from 'domains/customer/screens/OfficeHour/utilities/utilities'

import { modifyTimeByMinutes } from 'domains/customer/screens/OfficeHour/utilities/date'
import { parametersKeys } from 'domains/customer/screens/OfficeHour/data'

import {
  dayOfWorkIndexPosition,
  translatedDayOfWork,
} from 'domains/customer/utilities/dayOfWork'

import styles from './OfficeHourDetails.module.scss'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

type Coverage = {
  id: string
  patrimony: {
    id: string
    name: string
  }
  accountCode: string
  centralName: string
  partitionName: string
}

const Details = () => {
  const { customer, account } = useCustomerContext()
  const { officeHourId } = useParams()
  const navigate = useNavigate()

  const { data: officeHour, isFetching } = useGetOfficeHours(
    officeHourId || '',
    !!officeHourId,
  )

  const formattedScales = useMemo(() => {
    if (officeHour) {
      const groupedBusinessDays = groupIntoBusinessDays(officeHour.scales)

      return groupedBusinessDays
    }

    return []
  }, [officeHour])

  const activeParameters = useMemo(() => {
    if (officeHour) {
      return Object.entries(parametersKeys)
        .filter(([_, parameterKey]) => officeHour[parameterKey])
        .map(([label]) => label)
    }

    return []
  }, [officeHour])

  const coverages: Coverage[] | undefined = useMemo(() => {
    if (officeHour && officeHour?.coverage) {
      const parsedCoverages: Coverage[] = [
        ...officeHour.coverage.accounts.map((coverage) => ({
          id: coverage.id,
          patrimony: {
            id: coverage.precedents.patrimony?.id || '',
            name: coverage.precedents.patrimony?.name || '-',
          },
          accountCode: coverage.accountCode || '-',
          centralName: coverage.precedents.central?.name || '-',
          partitionName: '-',
        })),
        ...officeHour.coverage.partitions.map((partition) => ({
          id: partition.id,
          patrimonyName: partition.precedents.patrimony?.name || '-',
          patrimony: {
            id: partition.precedents.patrimony?.id || '',
            name: partition.precedents.patrimony?.name || '-',
          },
          accountCode: partition.precedents.account?.name || '-',
          centralName: partition.precedents.central?.name || '-',
          partitionName: partition.name || '-',
        })),
        ...officeHour.coverage.patrimonies.map((patrimony) => ({
          id: patrimony.id,
          patrimony: {
            id: patrimony?.id || '',
            name: patrimony?.name || '-',
          },
          accountCode: '-',
          centralName: '-',
          partitionName: '-',
        })),
      ]
      return parsedCoverages
    }
  }, [officeHour])

  if (isFetching || !officeHour) return <Loader isVisible />

  return (
    <>
      <ContainerScreen
        renderBreadcrumbs={
          <Breadcrumbs
            items={[
              { title: 'Home', href: '/' },
              { title: 'Contas', href: paths.account.list },
              {
                title: 'Tabela de horários',
                href: buildPath({
                  path: paths.account.details + '?tab=officeHour&page=1',
                  params: { accountId: account?.id },
                }),
              },
              { title: customer?.name || '', href: '#' },
            ]}
          />
        }
        clickable
      >
        <div className={styles.container}>
          <div>
            <p className={styles.title}>Abrangência</p>
            <div className={styles.shadows}>
              <div className={styles.coveragesContainer}>
                {coverages?.map((coverage) => (
                  <CoverageCard
                    key={coverage.id}
                    coverage={coverage}
                    onInspect={() =>
                      navigate(
                        buildPath({
                          path: paths.account.details + '?tab=patrimony',
                          params: {
                            accountId: account?.id,
                          },
                        }),
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.officeHours}>
            <p className={styles.title}>Tabela de horários</p>
            <div className={styles.scaleTable}>
              {formattedScales
                .sort(
                  (a, b) =>
                    dayOfWorkIndexPosition[a.dayOfWork] -
                    dayOfWorkIndexPosition[b.dayOfWork],
                )
                .map((scale) => (
                  <div key={scale.dayOfWork} className={styles.scales}>
                    <div className={styles.scaleContainer}>
                      <span className={styles.dayOfWork}>
                        {translatedDayOfWork[scale.dayOfWork]}
                        {scale.dayOfWork === 'HOLIDAY' && (
                          <IconWithTooltip
                            Icon={Info}
                            text="Feriados cadastrados via sistema"
                          />
                        )}
                      </span>
                      <div className={styles.scalesOfDay}>
                        {scale.periods
                          ?.sort(
                            (a, b) =>
                              new Date(
                                `1970-01-01T${a.startTime}:00`,
                              ).getTime() -
                              new Date(
                                `1970-01-01T${b.startTime}:00`,
                              ).getTime(),
                          )
                          .map((period, index) => {
                            const partitionLabel =
                              period.partitionStatus === PartitionStatus.ARMED
                                ? 'Armado'
                                : 'Desarmado'

                            return (
                              <div
                                className={styles.periodWrapper}
                                key={`${period.startTime}${index}`}
                              >
                                <span className={styles.label}>
                                  {partitionLabel}
                                </span>
                                <span className={styles.hour}>
                                  {`${period.startTime} - ${modifyTimeByMinutes(
                                    period.endTime,
                                    +1,
                                  )}`}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={styles.configs}>
              <div>
                <div className={styles.transitionTitle}>Tempo de transição</div>
                <div className={styles.configItem}>
                  Tolerância
                  <IconWithTooltip
                    Icon={Info}
                    text="Período de transição entre arme e desarme"
                  />
                  <span className={styles.toleranceTime}>
                    {officeHour?.tolerance} minutos
                  </span>
                </div>
              </div>
              <div>
                <div className={styles.transitionTitle}>
                  Controle de eventos
                </div>
                <div className={styles.configItem}>
                  <ul>
                    {activeParameters.map((label, index) => (
                      <li className={styles.events} key={index}>
                        {label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerScreen>

      <div className={styles.footer}>
        <Button
          id="edit-officeHour"
          type="secondary"
          buttonTitle="Editar tabela"
          icon={EditIcon}
          width="172px"
          onClick={() =>
            navigate(
              buildPath({
                path: paths.account.officeHour.update,
                params: {
                  accountId: account?.id,
                  officeHourId: officeHour.id,
                },
              }),
              {
                state: {
                  officeHoursData: {
                    ...officeHour,
                    parameters: {
                      active: officeHour?.active,
                      armedOutOfScaleActive: officeHour?.armedOutOfScaleActive,
                      disarmedOutOfScaleActive:
                        officeHour?.disarmedOutOfScaleActive,
                      notArmedActive: officeHour?.notArmedActive,
                      notDisarmedActive: officeHour?.notDisarmedActive,
                    },
                    scales: formattedScales,
                  },
                },
              },
            )
          }
        />
        <Button
          id="back-officeHour"
          type="secondary"
          buttonTitle="Voltar"
          width="172px"
          onClick={() => navigate(-1)}
        />
      </div>
    </>
  )
}

export default Details
